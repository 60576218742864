<template>
  <div class="app-container">
    <nav>
      <div class="operation">
        <el-date-picker
          v-model="dateVlaue"
          type="daterange"
          align="left"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          class="datePicker"
          value-format="yyyy-MM-dd HH:mm:ss"
          :picker-options="pickerOptions">
        </el-date-picker>
        <span class="user">选择用户</span>
        <el-select v-model="uId" placeholder="请选择" class="selectUser" @change="selectUserId(uId)"  >
            <el-option v-for="item in userList" :key="item.userId" :label="item.username" :value="item.userId" />
        </el-select>
        <el-input placeholder="请输入操作内容的关键字搜索" v-model="queryValue" maxlength="30" class="inputSearch" @keyup.enter.native="searchList">
          <i class="iconfont iconicon1-30" slot="suffix" @click="searchList"></i>
        </el-input>
        <el-button type="primary" @click="searchList" v-auth='"operationLog:lookup"'>查询</el-button>
      </div>
    </nav>

    <div class="filter-container">
      <books-table :value='queryValue' :userId='uId' :dateVlaue='dateVlaue' ref="booksTable" />
    </div>
  </div>
</template>

<script>
  import BooksTable from './modules/booksTable'
  export default {
    components: { BooksTable },
    data() {
      return {
        queryValue: '',
        userId: this.getToken('userId'),
        userName: this.getToken('userName'),
        userList: [],  //用户列表
        dateVlaue: [],
        uId: '',
        pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }],
          onPick: ({ maxDate, minDate }) => {
            this.choiceDate = minDate.getTime()
            if (maxDate) {
              this.choiceDate = ''
            }
          },
          disabledDate: (time) => {
            if (this.choiceDate) {
              const day1 =  30 * 24 * 3600 * 1000
              let maxTime = this.choiceDate + day1
              let minTime = this.choiceDate - day1
              return time.getTime() > maxTime || time.getTime()<this.choiceDate || time.getTime() > Date.now()
            }else{
              return time.getTime() > Date.now()+0 * 24 * 3600 * 1000 ||time.getTime() < Date.now()- 30 * 24 * 3600 * 1000
            }

          }
        },
      }
    },
    methods: {
      selectUserId(userId) {
        this.userId = userId
      },
      searchList() {
        this.$refs.booksTable.getList();
      },
      toPath() {
        this.$router.push('/install/voucherGroup/add')
      },
      listUser() {
        this.$store.dispatch("listUser",{companyId: this.getToken('companyId'), index: 1,rows:100}).then(res => {
          if (res.success) {
            this.userList = res.data;
            if(this.userList){
              this.uId=this.userList[0].userId;
              let _this=this;
              setTimeout(function(){
                _this.searchList(); //实例列表
              },300)
            }
          } else {
            einvAlert.error("提示",res.msg)
          }
        }).catch(err => {
        })
      }
    },
    mounted() {
      this.listUser(); //用户列表
    },
  }
</script>

<style lang='less' scoped>
  nav {
    height: 60px;
    display: flex;
    padding: 0 18px;
    // line-height: 60px;
    align-items: center;
    background-color: #fff;
    justify-content: space-between;
    .tips {
      font-size: 16px;
      span {
        margin-left: 18px;
      }
      .num {
        color: #4f71ff;
        font-style: normal;
      }
      .btn{
        width: 120px;
        .iconfont{
          font-size: 12px;
          margin-right: 5px;
          margin-left: -5px;
        }
      }
    }
    .operation {
      // display: flex;
      .inputSearch {
        width: 300px;
        margin-right: 20px;
        /deep/ .el-input__inner {
          border-radius: 5px !important;
          height: 35px !important;
          // line-height: 35px;
        }
        /deep/ .el-input__suffix {
          right: 16px;
          top: 10px;
          color: #4f71ff;
        }
      }
      .el-date-editor--daterange{
        width: 400px !important;
      }
      .datePicker{
        width: 400px;
        margin-right: 20px;
      }
      .user{
        line-height: 1;
        font-size: 15px;
        margin-right: 10px;
      }
      .selectUser{
        margin-right: 20px;
      }
      i {
        padding: 10px 8px;
        cursor: pointer;
      }
    }
  }

  .filter-container {
    padding: 1.04rem 1.04rem 3.13rem;
    height: calc(100% - 90px);
  }
</style>
